import React from "react";
import { content } from "../Models and Data/Content";
import { ContentType } from "../Models and Data/ContentType";
import HomeRow from "./Partials/HomeRow";

interface SearchProps {
  searchString: string;
  setShowContent: React.Dispatch<React.SetStateAction<boolean>>;
  setContentToShow: React.Dispatch<
    React.SetStateAction<ContentType | undefined>
  >;
}

const Search: React.FC<SearchProps> = ({
  searchString,
  setShowContent,
  setContentToShow,
}) => {
  return (
    <div className="search">
      <div className="search-text">
        Search Results related to "{searchString}"
      </div>
      <div className="search-content">
        {content
          .filter((element) =>
            Object.values(element).some(
              (value) =>
                (typeof value === "string" &&
                  value.toLowerCase().includes(searchString.toLowerCase())) ||
                (Array.isArray(value) &&
                  value
                    .join(" ")
                    .toLowerCase()
                    .includes(searchString.toLowerCase()))
            )
          )
          .map((content, index) => (
            <React.Fragment key={`${searchString}-${index}`}>
              <div
                onClick={() => {
                  setShowContent(true);
                  setContentToShow(content);
                  document.body.classList.add("hide-scroll-bar");
                }}
                className="search-image-container"
              >
                <img
                  className="home-row-carousel-img"
                  src={content.photos[0]}
                />
              </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default Search;
