import React, {
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import FrontBanner1 from "../assets/FrontBanner1.webp";
import FrontBanner2 from "../assets/FrontBanner2.webp";
import FrontBanner3 from "../assets/FrontBanner3.webp";
import FrontBanner4 from "../assets/FrontBanner4.webp";
import FrontBanner5 from "../assets/FrontBanner5.webp";

import Slider from "react-slick";
import { FaAngleDoubleRight } from "react-icons/fa";
import HomeRow from "./Partials/HomeRow";
import Modal from "./Modal";
import ContentPage from "./ContentPage";
import { ContentType } from "../Models and Data/ContentType";

interface HomeProps {
  scrollToContent: boolean;
  setScrollToContent: React.Dispatch<React.SetStateAction<boolean>>;
  navRefCurrent: HTMLDivElement | null;
  whatsNewRef: React.RefObject<HTMLDivElement>;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  setShowContent: React.Dispatch<React.SetStateAction<boolean>>;

  setContentToShow: React.Dispatch<
    React.SetStateAction<ContentType | undefined>
  >;

  setShowModal: React.Dispatch<SetStateAction<boolean>>;
}

const Home: React.FC<HomeProps> = ({
  scrollToContent,
  setScrollToContent,
  navRefCurrent,
  whatsNewRef,
  setSelected,
  setShowContent,

  setContentToShow,

  setShowModal,
}) => {
  useLayoutEffect(() => {
    if (scrollToContent && whatsNewRef.current && navRefCurrent) {
      const scrollToY = whatsNewRef.current.getBoundingClientRect().top;
      const navHeight = navRefCurrent.getBoundingClientRect().height;
      window.scrollTo({
        top: scrollToY - navHeight,
        behavior: "smooth",
      });
      setScrollToContent(false);
    }
  }, [scrollToContent, whatsNewRef.current, navRefCurrent]);

  return (
    <div
      className="home"
      onClick={() => {
        setSelected("");
      }}
    >
      <div className="home-carousel">
        <Slider
          arrows={false}
          dots
          infinite
          speed={1000}
          autoplay
          autoplaySpeed={3000}
          slidesToShow={1}
          slidesToScroll={1}
          draggable
        >
          <div>
            <img className="home-carousel-img" src={FrontBanner1} />
          </div>
          <div>
            <img className="home-carousel-img" src={FrontBanner2} />
          </div>
          <div>
            <img className="home-carousel-img" src={FrontBanner3} />
          </div>
          <div>
            <img className="home-carousel-img" src={FrontBanner4} />
          </div>
          <div>
            <img className="home-carousel-img" src={FrontBanner5} />
          </div>
        </Slider>
        <div className="home-learn-more-wrapper">
          <div className="home-shamanta">Shamanta</div>
          <div className="home-haider">Haider</div>
          <div className="home-learn-more" onClick={() => setShowModal(true)}>
            Learn More <FaAngleDoubleRight />
          </div>
        </div>
      </div>
      <HomeRow
        text="What's New"
        whatsNewRef={whatsNewRef}
        setShowContent={setShowContent}
        setContentToShow={setContentToShow}
      />
      <HomeRow
        text="Film/TV"
        setShowContent={setShowContent}
        setContentToShow={setContentToShow}
      />
      <HomeRow
        text="Live Sports"
        setShowContent={setShowContent}
        setContentToShow={setContentToShow}
      />
      <HomeRow
        text="PR & Marketing"
        setShowContent={setShowContent}
        setContentToShow={setContentToShow}
      />
      <HomeRow
        text="Theatre"
        setShowContent={setShowContent}
        setContentToShow={setContentToShow}
      />
    </div>
  );
};

export default Home;
