import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ShamantaLogo from "../assets/ShamantaLogo.webp";
import { FaBell, FaCaretDown, FaHome, FaPhone, FaSearch } from "react-icons/fa";
import { LuBellDot } from "react-icons/lu";
import Projects from "./Partials/Projects";

interface NavProps {
  setScrollToContent: React.Dispatch<React.SetStateAction<boolean>>;
  navRef: React.RefObject<HTMLDivElement>;
  whatsNewRef: React.RefObject<HTMLDivElement>;
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

const Nav: React.FC<NavProps> = ({
  setScrollToContent,
  navRef,
  whatsNewRef,
  selected,
  setSelected,
  searchString,
  setSearchString,
}) => {
  const [search, setSearch] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [showNav, setShowNav] = useState(false);

  const burgerWidth = 1064;

  useLayoutEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  });

  useLayoutEffect(() => {
    window.addEventListener("scroll", () => {
      if (navRef.current && whatsNewRef.current) {
        const whatsNewTop = whatsNewRef.current.getBoundingClientRect().top;
        const navHeight = navRef.current.getBoundingClientRect().height;
        if (navHeight < whatsNewTop - 25) {
          setSelected("");
        } else {
          setSelected("What's New");
        }
      }
    });
  }, [navRef.current, whatsNewRef.current]);

  const navItems: string[] = [
    "Home",
    "Projects",
    "What's New",
    "Contact",
    "Search",
  ];

  useEffect(() => {
    if (searchRef.current && search) {
      searchRef.current.focus();
    }
  }, [searchRef.current, search]);

  return width > burgerWidth ? (
    <div className="nav" ref={navRef}>
      <div className="nav-items">
        <div
          className={`nav-item${selected === "" ? " nav-item-selected" : ""}`}
          onClick={() => {
            setSelected("");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Home <FaHome />
        </div>
        <div
          className={`nav-item${
            selected === "Projects" ? " nav-item-selected" : ""
          }`}
          onClick={() => setSelected(selected === "Projects" ? "" : "Projects")}
        >
          Projects{" "}
          <FaCaretDown
            className={
              selected === "Projects"
                ? "projects-caret projects-caret-selected"
                : "projects-caret"
            }
          />
        </div>
        <div
          className={`nav-item${
            selected === "What's New" ? " nav-item-selected" : ""
          }`}
          onClick={() => {
            setSelected("What's New");
            setScrollToContent(true);
          }}
        >
          What's New <FaBell />
        </div>
        <div
          className={`nav-item${
            selected === "Contact" ? " nav-item-selected" : ""
          }`}
          onClick={() => setSelected("Contact")}
        >
          Contact <FaPhone />
        </div>

        <div
          className={`nav-item`}
          style={{ position: "relative" }}
          onClick={() => setSearch(true)}
        >
          <span
            className={`nav-item-search-text${
              search ? "" : " nav-item-search-text-show"
            }`}
          >
            Search
          </span>
          <FaSearch
            className="nav-item-search-icon"
            style={search ? { left: "0" } : undefined}
          />
          <input
            ref={searchRef}
            onBlur={() => {
              setSearch(false);
              searchRef.current!.value = "";
            }}
            type="text"
            className={`nav-input${search ? " nav-input-show" : ""}`}
            placeholder="title, description, genre, etc."
            onChange={(e) => {
              setSelected("Search");
              setSearchString(e.target.value);
            }}
          />
        </div>

        {/* {navItems.map((navItem, index) => (
        <React.Fragment key={`nav-${index}`}>
          <Link
            to={`/${getPathName(navItem)}`}
            className={`nav-item${
              selected === getPathName(navItem) ? " nav-item-selected" : ""
            }`}
            onClick={() => setSelected(getPathName(navItem))}
          >
            {navItem}
            {navItem === "Home" ? (
              <FaHome />
            ) : navItem === "Projects" ? (
              <FaCaretDown />
            ) : navItem === "What's New" ? (
              <FaBell />
            ) : navItem === "Contact" ? (
              <FaPhone />
            ) : navItem === "Search" ? (
              <FaSearch />
            ) : null}
          </Link>
        </React.Fragment>
      ))} */}
        <div className="nav-item">
          <img
            className="nav-logo"
            src={ShamantaLogo}
            onClick={() => {
              setSelected("");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </div>
      </div>
      <Projects selected={selected} width={width} />
    </div>
  ) : (
    <div className="nav-mobile">
      <div className="nav-item">
        <img
          className="nav-logo nav-logo-mobile"
          src={ShamantaLogo}
          onClick={() => {
            setSelected("");
            window.scrollTo({ top: 0, behavior: "smooth" });
            setShowNav(false);
          }}
        />
      </div>
      <div
        className={`hamburger-btn${showNav ? " active" : ""}`}
        onClick={() => setShowNav(!showNav)}
      >
        <div className="line line-1"></div>
        <div className="line line-2"></div>
        <div className="line line-3"></div>
      </div>
      <div className={`nav-content${showNav ? " nav-content-show" : ""}`}>
        <div className="nav-items-mobile">
          <div
            className={`nav-item${selected === "" ? " nav-item-selected" : ""}`}
            onClick={() => {
              setSelected("");
              window.scrollTo({ top: 0, behavior: "smooth" });
              setShowNav(false);
            }}
          >
            Home <FaHome />
          </div>
          <div
            className={`nav-item${
              selected === "Projects" ? " nav-item-selected" : ""
            }`}
            onClick={() => {
              setSelected(selected === "Projects" ? "" : "Projects");
              setShowNav(false);
            }}
          >
            Projects{" "}
            <FaCaretDown
              className={
                selected === "Projects"
                  ? "projects-caret projects-caret-selected"
                  : "projects-caret"
              }
            />
          </div>
          <div
            className={`nav-item${
              selected === "What's New" ? " nav-item-selected" : ""
            }`}
            onClick={() => {
              setSelected("What's New");
              setScrollToContent(true);
              setShowNav(false);
            }}
          >
            What's New <FaBell />
          </div>
          <div
            className={`nav-item${
              selected === "Contact" ? " nav-item-selected" : ""
            }`}
            onClick={() => {
              setSelected("Contact");
              setShowNav(false);
            }}
          >
            Contact <FaPhone />
          </div>

          <div
            className={`nav-item`}
            style={{ position: "relative" }}
            onClick={() => setSearch(true)}
          >
            <span
              className={`nav-item-search-text${
                search ? "" : " nav-item-search-text-show"
              }`}
            >
              Search
            </span>
            <FaSearch
              className="nav-item-search-icon nav-item-search-icon-mobile"
              style={search ? { left: "0" } : undefined}
            />
            <input
              ref={searchRef}
              onBlur={() => {
                setSearch(false);
                searchRef.current!.value = "";
              }}
              type="text"
              className={`nav-input nav-input-mobile${
                search ? " nav-input-show" : ""
              }`}
              placeholder="title, description, genre, etc."
              onChange={(e) => {
                setSelected("Search");
                setSearchString(e.target.value);
              }}
            />
          </div>

          {/* {navItems.map((navItem, index) => (
        <React.Fragment key={`nav-${index}`}>
          <Link
            to={`/${getPathName(navItem)}`}
            className={`nav-item${
              selected === getPathName(navItem) ? " nav-item-selected" : ""
            }`}
            onClick={() => setSelected(getPathName(navItem))}
          >
            {navItem}
            {navItem === "Home" ? (
              <FaHome />
            ) : navItem === "Projects" ? (
              <FaCaretDown />
            ) : navItem === "What's New" ? (
              <FaBell />
            ) : navItem === "Contact" ? (
              <FaPhone />
            ) : navItem === "Search" ? (
              <FaSearch />
            ) : null}
          </Link>
        </React.Fragment>
      ))} */}
        </div>
      </div>
      <Projects selected={selected} width={width} />
    </div>
  );
};

export default Nav;
