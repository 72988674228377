import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router";
import Nav from "./Components/Nav";
import Home from "./Components/Home";
import Contact from "./Components/Contacts";
import Search from "./Components/Search";
import { ContentType } from "./Models and Data/ContentType";
import Modal from "./Components/Modal";
import ContentPage from "./Components/ContentPage";

function App() {
  const [scrollToContent, setScrollToContent] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const whatsNewRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<string>("");
  const [showContent, setShowContent] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>("");
  const [contentToShow, setContentToShow] = useState<ContentType | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setShowContent(false);
    document.body.classList.remove("hide-scroll-bar");
  }, [selected]);

  return (
    <div className="App">
      <Nav
        setScrollToContent={setScrollToContent}
        navRef={navRef}
        whatsNewRef={whatsNewRef}
        selected={selected}
        setSelected={setSelected}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <ContentPage
        showContent={showContent}
        setShowContent={setShowContent}
        setContent={setContentToShow}
        content={contentToShow}
      />
      {(selected == "" ||
        selected == "What's New" ||
        selected == "Projects") && (
        <Home
          scrollToContent={scrollToContent}
          setScrollToContent={setScrollToContent}
          navRefCurrent={navRef.current}
          setShowContent={setShowContent}
          setContentToShow={setContentToShow}
          whatsNewRef={whatsNewRef}
          setShowModal={setShowModal}
          setSelected={setSelected}
        />
      )}
      {selected == "Contact" && <Contact />}
      {selected == "Search" && (
        <Search
          searchString={searchString}
          setShowContent={setShowContent}
          setContentToShow={setContentToShow}
        />
      )}
    </div>
  );
}

export default App;
