import React, { useEffect } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import FrontBanner1 from "../assets/FrontBanner1.webp";

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<ModalProps> = ({ showModal, setShowModal }) => {
  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape" || e.key == "Esc") {
        setShowModal(false);
      }
    });
  });

  return (
    <div className={`modal${showModal ? " modal-show" : ""}`}>
      <span className="modal-close" onClick={() => setShowModal(false)}>
        X
      </span>
      <img className="modal-banner" src={FrontBanner1} />
      <div className="modal-banner-fade"></div>
      <div className="modal-tagline-container">
        <div className="modal-hi-there">Hi There!</div>
        <div className="modal-welcome">Welcome to my digital portfolio.</div>
        <div className="modal-play-video">
          Play Video <FaAngleDoubleRight />
        </div>
        <div className="modal-rating-container">
          <div className="modal-imdb">IMDb</div>
          <div className="modal-imdb-rating">9.9</div>

          <div className="modal-rt">Rotten Tomatoes</div>
          <div className="modal-rt-rating">90%</div>

          <div className="modal-tvma">TV-MA</div>
        </div>
      </div>
      <div className="modal-content-container">
        <div className="modal-content-left">
          If I had to describe myself in one word, it would be{" "}
          <strong>storyteller</strong>. As you explore this site, you will find
          that I have a lot of different interests, but the one thing I’ve
          always been passionate about is film & television production and
          telling stories. I love designing and creating content that helps
          connect people.
        </div>
        <div className="modal-content-right">
          <div className="modal-content-right-starring">
            <span className="modal-content-delineator">Starring: </span>Shamanta
            Haider
          </div>
          <div className="modal-content-right-genres">
            <span className="modal-content-delineator">Genres: </span>
            Film/TV, Design and Content Creation, Theatre, and Digital Marketing
            and PR
          </div>
          <div className="modal-content-right-languages">
            <span className="modal-content-delineator">Languages: </span>
            English, Bengali, Hindi, Urdu
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
