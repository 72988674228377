import React from "react";

interface ProjectsProps {
  selected: string;
  width: number;
}

const Projects: React.FC<ProjectsProps> = ({ selected, width }) => {
  return (
    <div
      className={`nav-projects${width > 1024 ? "" : " nav-projects-mobile"}${
        selected === "Projects" ? " nav-projects-show" : ""
      }`}
    >
      <div className="nav-projects-container">
        <div className="nav-projects-heading">Film/TV</div>
        <div className="nav-projects-item">Producer</div>
        <div className="nav-projects-item">Screenwriter</div>
        <div className="nav-projects-item">Video Editor</div>
        <div className="nav-projects-item">Studio Production</div>
      </div>
      <div className="nav-projects-container">
        <div className="nav-projects-heading">Theatre</div>
        <div className="nav-projects-item">Actor</div>
        <div className="nav-projects-item">Stage Manager</div>
        <div className="nav-projects-item">Head of Props and Paint</div>
        <div className="nav-projects-item">Technical Crew</div>
      </div>
      <div className="nav-projects-container">
        <div className="nav-projects-heading">PR {"&"} Marketing</div>
        <div className="nav-projects-item">Blog Posts</div>
        <div className="nav-projects-item">
          Mass Pitches {"&"} Press Releases
        </div>
        <div className="nav-projects-item">Social Media Management</div>
      </div>
    </div>
  );
};

export default Projects;
