import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact: React.FC = () => {
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const sendEmail = async () => {
    if (nameRef.current && emailRef.current && messageRef.current) {
      if (
        nameRef.current.value === "" ||
        emailRef.current.value === "" ||
        messageRef.current.value === ""
      ) {
        alert("Please fill out each field.");
      } else {
        if (isValidEmail(emailRef.current.value)) {
          const res = await emailjs.send(
            "service_shamu",
            "template_shamu-contact",
            {
              from_name: `${nameRef.current.value}`,
              from_email: `${emailRef.current.value}`,
              message: `${messageRef.current.value}`,
            },
            "mhwotUv6Yzx-Ts109"
          );
          console.log(res);
        } else {
          alert("Please enter a valid email.");
        }
      }
    } else {
      alert("Components not active.");
    }
  };

  const isValidEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  return (
    <div className="contact">
      <div className="contact-top">
        <div className="contact-heading">Contact Me!</div>
        <div className="contact-blurb">
          If you're working on a fun project or if you just want to chat, feel
          free to reach out!
        </div>
      </div>
      <div className="contact-bottom">
        <div className="contact-left">
          <div className="contact-left-top">
            <div className="contact-section-heading">Name</div>
            <div className="contact-name">Shamanta Haider</div>
          </div>
          <div className="contact-left-middle">
            <div className="contact-section-heading">Location</div>
            <div className="contact-location">ON, Canada and USA</div>
          </div>
          <div className="contact-left-bottom">
            <div className="contact-section-heading">Contact Info</div>
            <div className="contact-phone">
              +1 {"("}416{")"} 451-0360
            </div>
            <div className="contact-email">haider.shamanta@gmail.com</div>
            <a
              className="contact-linkedin"
              href="https://www.linkedin.com/in/shamanta-haider/"
              target="_blank"
              style={{ color: "#fff" }}
            >
              https://www.linkedin.com/in/shamanta-haider/
            </a>
          </div>
        </div>
        <div className="contact-right">
          <div className="contact-right-top">
            <div className="contact-section-heading">Your name</div>
            <input
              type="text"
              className="contact-input-name"
              placeholder="Name"
              ref={nameRef}
            />
          </div>
          <div className="contact-right-middle">
            <div className="contact-section-heading">Your email</div>
            <input
              type="text"
              className="contact-input-email"
              placeholder="Email"
              ref={emailRef}
            />
          </div>
          <div className="contact-right-bottom">
            <div className="contact-section-heading">Your message</div>
            <textarea
              className="contact-input-message"
              placeholder="Message"
              ref={messageRef}
            />
          </div>
          <div className="contact-submit" onClick={() => sendEmail()}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
