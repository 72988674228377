import React, { useEffect, useState } from "react";
import react from "react";
import Slider from "react-slick";
import { content } from "../../Models and Data/Content";
import { ContentType } from "../../Models and Data/ContentType";

interface HomeRowProps {
  text: string;
  whatsNewRef?: React.RefObject<HTMLDivElement>;
  setShowContent: React.Dispatch<React.SetStateAction<boolean>>;
  setContentToShow: React.Dispatch<
    React.SetStateAction<ContentType | undefined>
  >;
}

const HomeRow: React.FC<HomeRowProps> = ({
  text,
  whatsNewRef,
  setShowContent,
  setContentToShow,
}) => {
  return (
    <div
      ref={text == "What's New" ? whatsNewRef : null}
      className={`home-row-container${
        text === "What's New"
          ? " whats-new-container"
          : text === "Theatre"
          ? " theatre-container"
          : ""
      }`}
    >
      <div className="home-row-text">{text}</div>
      <div className="home-row-content">
        {text === "What's New" ? (
          <Slider
            infinite
            speed={500}
            slidesToShow={
              window.innerWidth < 700
                ? 1
                : window.innerWidth < 1040
                ? 2
                : window.innerWidth < 1400
                ? 3
                : 4
            }
            slidesToScroll={
              window.innerWidth < 1040 ? 1 : window.innerWidth < 1400 ? 2 : 3
            }
          >
            {content
              .sort(
                (first, second) => second.date.valueOf() - first.date.valueOf()
              )
              .slice(0, 8)
              .map((content, index) => (
                <React.Fragment key={`${text}-${index}`}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowContent(true);
                      setContentToShow(content);
                      document.body.classList.add("hide-scroll-bar");
                    }}
                    className="home-row-image-container"
                  >
                    <img
                      className="home-row-carousel-img"
                      src={content.photos[0]}
                    />
                  </div>
                </React.Fragment>
              ))}
          </Slider>
        ) : text === "PR & Marketing" ? (
          window.innerWidth < 1040 ? (
            <Slider
              infinite
              speed={500}
              slidesToShow={
                window.innerWidth < 700
                  ? 1
                  : window.innerWidth < 1040
                  ? 2
                  : window.innerWidth < 1400
                  ? 3
                  : 4
              }
              slidesToScroll={
                window.innerWidth < 1040 ? 1 : window.innerWidth < 1400 ? 2 : 3
              }
            >
              {content
                .filter((element) => element.category === text)
                .map((content, index) => (
                  <React.Fragment key={`${text}-${index}`}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowContent(true);
                        setContentToShow(content);
                        document.body.classList.add("hide-scroll-bar");
                      }}
                      className="home-row-image-container"
                    >
                      <img
                        className="home-row-carousel-img"
                        src={content.photos[0]}
                      />
                    </div>
                  </React.Fragment>
                ))}
            </Slider>
          ) : (
            <div className="home-row-content-pr-marketing">
              {content
                .filter((element) => element.category === text)
                .map((content, index) => (
                  <React.Fragment key={`${text}-${index}`}>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowContent(true);
                        setContentToShow(content);
                        document.body.classList.add("hide-scroll-bar");
                      }}
                      className="home-row-image-container"
                    >
                      <img
                        className="home-row-carousel-img"
                        src={content.photos[0]}
                      />
                    </div>
                  </React.Fragment>
                ))}
            </div>
          )
        ) : (
          <Slider
            infinite
            speed={500}
            slidesToShow={
              window.innerWidth < 700
                ? 1
                : window.innerWidth < 1040
                ? 2
                : window.innerWidth < 1400
                ? 3
                : 4
            }
            slidesToScroll={
              window.innerWidth < 1040 ? 1 : window.innerWidth < 1400 ? 2 : 3
            }
          >
            {content
              .filter((element) => element.category === text)
              .map((content, index) => (
                <React.Fragment key={`${text}-${index}`}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowContent(true);
                      setContentToShow(content);
                      document.body.classList.add("hide-scroll-bar");
                    }}
                    className="home-row-image-container"
                  >
                    <img
                      className="home-row-carousel-img"
                      src={content.photos[0]}
                    />
                  </div>
                </React.Fragment>
              ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default HomeRow;
