import React, { MutableRefObject, useEffect, useState } from "react";
import { FaAngleDoubleRight } from "react-icons/fa";
import { ContentType } from "../Models and Data/ContentType";

interface ContentProps {
  showContent: boolean;
  setShowContent: React.Dispatch<React.SetStateAction<boolean>>;
  content?: ContentType;
  setContent: React.Dispatch<React.SetStateAction<ContentType | undefined>>;
}

interface ShowImageType {
  image?: string;
  show: boolean;
}

const ContentPage: React.FC<ContentProps> = ({
  showContent,
  setShowContent,
  content,
  setContent,
}) => {
  const [showImage, setShowImage] = useState<ShowImageType>({ show: false });
  const closeModal = () => {
    setContent(undefined);
    setShowContent(false);
    document.body.classList.remove("hide-scroll-bar");
  };

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        setShowImage((prev: ShowImageType) => ({
          show: false,
          image: prev.image,
        }));
      }
    });
  });

  return (
    <div
      className={`content-page${showContent ? " content-page-show" : ""}`}
      onClick={() =>
        setShowImage((prev: ShowImageType) => ({
          show: false,
          image: prev.image,
        }))
      }
    >
      <img
        className={`content-page-image${
          showImage.show ? " content-page-image-show" : ""
        }`}
        src={showImage.image}
      >
        {/* <img className="content-page-image-display" src={showImage.image} /> */}
      </img>
      <div className="content-page-container">
        <span className="content-page-close" onClick={() => closeModal()}>
          X
        </span>
        <img src={content?.photos[0]} className="content-page-banner" />
        <div className="content-page-banner-fade"></div>
        <div className="content-page-tagline-container">
          <div
            className="content-page-tagline-top"
            style={{ fontSize: content?.fontSizeTop }}
          >
            {content?.titleTop}
          </div>
          <div
            className="content-page-tagline-bottom"
            style={{ fontSize: content?.fontSizeBottom }}
          >
            {content?.titleBottom}
            {content?.name === "Tomorrow Love" && (
              <span style={{ fontSize: "3.9vw", position: "absolute" }}>
                &trade;
              </span>
            )}
          </div>
          {content?.url && content.url !== "" && (
            <div
              className="content-page-learn-more"
              onClick={() => window.open(content!.url, "_blank")}
            >
              Learn More <FaAngleDoubleRight />
            </div>
          )}
          <div className="content-page-rating-container">
            {/* <div className="content-page-imdb">IMDb</div>
          <div className="content-page-imdb-rating">9.9</div>

          <div className="content-page-rt">Rotten Tomatoes</div>
          <div className="content-page-rt-rating">90%</div>

          <div className="content-page-tvma">TV-MA</div> */}
            <div className="content-page-year">Description</div>
          </div>
        </div>
        <div className="content-page-section">
          <div className="content-page-description">
            {content?.description.join(" ")}
            {content?.description && content.description.length > 0 && (
              <>
                <br />
                <br />
              </>
            )}
            {content?.responsibilities &&
              content.responsibilities.length > 0 && (
                <div className="content-page-responsibilities">
                  Responsibilities:
                </div>
              )}
            {content?.responsibilities.join(" ")}
          </div>
          <div className="content-page-details">
            <div className="content-page-details-text">
              <span className="content-page-delineator">Role:</span>{" "}
              {content?.role}
            </div>
            <div className="content-page-details-text">
              <span className="content-page-delineator">Genre:</span>{" "}
              {content?.genre}
            </div>
            <div className="content-page-details-text">
              <span className="content-page-delineator">Location:</span>{" "}
              {content?.location}
            </div>
            <div className="content-page-details-text">
              <span className="content-page-delineator">Organization:</span>{" "}
              {content?.organization !== ""
                ? content?.organization
                : content.name}
            </div>
            <div className="content-page-details-text">
              <span className="content-page-delineator">Year:</span>{" "}
              {content?.date.getFullYear()}
            </div>
            {content?.credits !== "" && (
              <div className="content-page-details-text">
                <span className="content-page-delineator">Credits:</span>{" "}
                {content?.credits}
              </div>
            )}
          </div>
        </div>
        {content && content.videos.length > 0 && (
          <div className="content-page-content">
            <div className="content-page-content-title">Videos</div>
            <div className="content-page-content-media-container">
              {content.videos.map((video, index) => (
                <React.Fragment key={`video-${index}`}>
                  <iframe
                    className="content-page-content-media content-page-iframe"
                    width="300"
                    height="200"
                    src={video}
                    title="YouTube video player"
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        {content && content.photos.length > 0 && (
          <div className="content-page-content">
            <div className="content-page-content-title">Photos</div>
            <div className="content-page-content-media-container">
              {content.photos.map((photo, index) => (
                <React.Fragment key={`photo-${index}`}>
                  <img
                    className="content-page-content-media"
                    src={photo}
                    style={{ marginBottom: "1.5rem" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowImage({ show: true, image: photo });
                    }}
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentPage;
